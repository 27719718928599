import axios from "axios"
import { ElMessage } from 'element-plus'
import cache from '../utils/cache'

const api = axios.create({
  baseURL: '/api'
})
api.interceptors.request.use(request => {
  const user = cache.getObject(cache.keys.loginUser)
  if (user && user.token) {
    request.headers.Token = user.token
  }
  request.data = request.data || {}
  return request
})
api.interceptors.response.use(res => {
  if (res.status != 200 || !res.data) {
    ElMessage.error(JSON.stringify(res))
    console.error(res)
    throw res
  }
  return res.data
})
export default api
import { ElMessage } from 'element-plus'
export default {
    /**
     * @returns 页面参数
     */
    getSearchParams() {
        const searchPar = new URLSearchParams(window.location.search)
        const paramsObj = {}
        for (const [key, value] of searchPar.entries()) {
            paramsObj[key] = value
        }
        return paramsObj
    },
    /**
     * 时间格式化
     * @param {any} time 日期时间
     * @param {string} pattern 表达式{y}{m}{d}{h}{i}{s}{a}
     * @returns 
     */
    parseTime(time, pattern) {
        if (arguments.length === 0 || !time) {
            return null
        }
        const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            } else if (typeof time === 'string') {
                time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '')
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            if (result.length > 0 && value < 10) {
                value = '0' + value
            }
            return value || 0
        })
        return time_str
    },
    /**
     * 文本内容复制到剪切板
     */
    clipBoard(value, callback) {
        navigator.clipboard.writeText(value)
        if (callback) {
            callback()
        } else {
            ElMessage.success('已复制到剪切板')
        }
    },
    /**
     * JSON文件地址转为El上传文件组件数据
     */
    json2Elfile(json) {
        try {
            const paths = JSON.parse(json)
            if (!paths || paths.length == 0) {
                return []
            }
            return paths.map(path => {
                return {
                    name: path.split('/').pop(),
                    path: path,
                    uid: path
                }
            })
        } catch {
            return []
        }
    },
    /**
     * El上传文件组件数据转为JSON文件地址
     */
    elfile2Json(Elfiles) {
        if (!Elfiles) {
            return ''
        }
        const paths = []
        for (const i in Elfiles) {
            const file = Elfiles[i]
            if (file.response && file.response.data && file.response.data.path) {
                paths.push(file.response.data.path)
            } else if (file.path) {
                paths.push(file.path)
            }
        }
        if (paths.length == 0) {
            return ''
        }
        return JSON.stringify(paths)
    },
    /**
     * 打开El上传文件组件中的文件
     */
    previewElfile(file) {
        let path = null
        if (file.response && file.response.data && file.response.data.path) {
            path = file.response.data.path
        } else if (file.path) {
            path = file.path
        }
        if (path) {
            window.open(path)
        }
    },
    /**
     * 下载文件
     * @param {Blob} obj 
     * @param {string} name 
     * @param {string} suffix 
     */
    downloadFile(obj, name, suffix) {
        const url = window.URL.createObjectURL(new Blob([obj]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        const fileName = name + '.' + suffix
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}